<div style="display: flex; flex-direction: column; gap: 16px">
  <vdr-card *ngIf="stop" style="grid-column: 1 / -1; margin-bottom: 8px">
    <div class="form-grid">
      <div>
        Valt stopp: <br />
        {{ stop.streetName }} {{ stop.streetNumber }}<br />{{ stop.zipCode }} {{ stop.city }}
      </div>
      <div>
        Valt upphämtningstillfälle: <br />
        {{ nicePickUpTime }}
      </div>
      <div>
        HashID: <br />
        {{ stop.stopHash }}
      </div>
      <div>
        DepotID: <br />
        {{ stop.depotId }}
      </div>
      <div>
        Depot name: <br />
        {{ stop.depotName }}
      </div>
      <div>
        Route name: <br />
        {{ stop.routeName }}
      </div>
    </div>
  </vdr-card>

  <div>
    <label>Ändra stopp:</label>
  <select [formControl]="stopHashFormControl" (change)="onStopSelected()">
    <option *ngFor="let stop of nearestStops" [value]="stop.stopHash">
      {{ stop.streetName }} {{ stop.streetNumber }} (+{{ stop.distance | number : '1.0-0' }}m) [{{
        stop.stopHash
      }}]
    </option>
  </select>
</div>

  <div>
    <label>Ändra datum:</label>
    <select [formControl]="pickUpTimeFormControl" (change)="onTimeSelected()">
      <option *ngFor="let time of nextTimes" [value]="time.value">
        {{ time.label }}
      </option>
    </select>
  </div>

  <p>Vid ändring av leveranstid/datum/stopp behöver du manuellt meddela detta till kunden.</p>
</div>