import { NgModule } from '@angular/core'
import { addActionBarItem, registerBulkAction, SharedModule } from '@vendure/admin-ui/core'
import { OrderExportDownloadService } from './order-export-download.service'
import { map, switchMap } from 'rxjs/operators';

@NgModule({
  imports: [SharedModule],
  providers: [
    addActionBarItem({
      id: 'print-order',
      label: 'Skriv ut',
      locationId: 'order-detail',
      onClick: async (event, { injector, route }) => {
        const orderId = route.snapshot.params.id
        const downloadService = injector.get(OrderExportDownloadService)
        await downloadService.download([orderId])
      },
      buttonState: (context) => {
        return context.route.data.pipe(
          switchMap((data) => data.detail.entity),
          map((order: any) => ({
            disabled: order.state === 'AddingItems',
            visible: true,
          })),
        )
      },
    }),
    registerBulkAction({
      label: 'Skriv ut',
      location: 'order-list',
      onClick: async ({ injector, selection }) => {
        const downloadService = injector.get(OrderExportDownloadService)
        const orderIds = selection.map((item) => item.id)
        await downloadService.download(orderIds)
      },
    }),
    OrderExportDownloadService,
  ],
})
export class OrderExportActionModule {}
