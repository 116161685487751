import { Component, ChangeDetectorRef, OnInit } from '@angular/core'
import { FormControl, FormGroup, FormsModule } from '@angular/forms'
import { SharedModule, FormInputComponent } from '@vendure/admin-ui/core'
import { VoyadoService } from '../voyado.service'
import dayjs from 'dayjs'

interface Stop {
  stopHash: string
  streetName: string
  streetNumber: string
  depotId: string
  zipCode: string
  city: string
  latitude: number
  longitude: number
  nextDate: Date
  pickUpTime?: string
}

@Component({
  templateUrl: './stop.component.html',
  standalone: true,
  imports: [SharedModule, FormsModule],
})
export class StopComponent implements FormInputComponent, OnInit {
  readonly: boolean
  config: any
  formControl: FormControl

  nearestStops: Omit<Stop, 'pickUpTime'>[] = []
  stop: Stop
  nicePickUpTime: string
  nextTimes: any[] = []

  stopHashFormControl: FormControl
  pickUpTimeFormControl: FormControl
  formGroup: FormGroup

  constructor(private voyadoService: VoyadoService, private cdRef: ChangeDetectorRef) {
    this.stopHashFormControl = new FormControl()
    this.pickUpTimeFormControl = new FormControl()

    this.formGroup = new FormGroup({
      stopHash: this.stopHashFormControl,
      pickUpTime: this.pickUpTimeFormControl,
    })
  }

  async ngOnInit() {
    this.stop = JSON.parse(this.formControl.value)
    this.nicePickUpTime =   dayjs(this.stop.pickUpTime).format('YYYY-MM-DD HH:mm')

    const { latitude, longitude } = await this.fetchLocationForHash(this.stop.stopHash)
    this.nearestStops = await this.fetchNearestHashedStops({ latitude, longitude })

    this.stopHashFormControl.setValue(this.stop.stopHash)
    this.pickUpTimeFormControl.setValue(this.stop.pickUpTime)

    await this.fetchTimesForHashedStopId(this.stop.stopHash)

    this.cdRef.detectChanges()
  }

  async onStopSelected() {
    const selectedStop = this.nearestStops.find((stop) => {
      return stop.stopHash === this.stopHashFormControl.value
    })

    if (selectedStop) {
      this.stop = selectedStop

      await this.fetchTimesForHashedStopId(selectedStop.stopHash)

      this.formControl.patchValue(
        JSON.stringify({
          ...selectedStop,
          pickUpTime: this.pickUpTimeFormControl.value,
        }),
      )

      this.formControl.markAsDirty()
    }
  }

  onTimeSelected() {
    this.stop.pickUpTime = this.pickUpTimeFormControl.value
    this.nicePickUpTime =   dayjs(this.pickUpTimeFormControl.value).format('YYYY-MM-DD HH:mm')
    this.formControl.patchValue(
      JSON.stringify({
        ...this.stop,
        pickUpTime: this.pickUpTimeFormControl.value,
      }),
    )

    this.formControl.markAsDirty()
  }

  async fetchTimesForHashedStopId(hashId: string) {
    await this.voyadoService.getNextTimesForHashedStopId(hashId).then((times) => {
      this.nextTimes = times.map((time) => {
        return {
          value: time,
          label: dayjs(time).format('YYYY-MM-DD HH:mm'),
        }
      })

      if (!this.nextTimes.find((time) => time.value === this.pickUpTimeFormControl.value)) {
        this.pickUpTimeFormControl.setValue(this.nextTimes[0].value)
      }
    })
  }

  fetchLocationForHash(hashId: string) {
    return this.voyadoService.locationForHash(hashId)
  }

  fetchNearestHashedStops({ latitude, longitude, limit = 500 }) {
    return this.voyadoService.getNearestHashedStops({ latitude, longitude, limit })
  }
}
