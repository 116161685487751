import { Injectable, OnInit } from '@angular/core'
import {
  DataService,
  LocalStorageService,
  NotificationService,
  getServerLocation,
} from '@vendure/admin-ui/core'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { OrderState } from '@vendure/core'

export interface ExportPluginConfig {
  vendureHost: string
  transitionToState?: OrderState
  exportableOrderStates?: OrderState[]
  openInNewTab?: boolean
}
@Injectable()
export class OrderExportDownloadService {
  serverPath: string
  private config: ExportPluginConfig

  constructor(
    protected dataService: DataService,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private apollo: Apollo,
  ) {
    this.serverPath = getServerLocation()
    this.getPluginConfig().subscribe(({ data }: { data: any }) => {
      this.config = data.getPluginConfig
    })
  }

  getPluginConfig() {
    return this.apollo.query({
      query: gql`
        query GetPluginConfig {
          getPluginConfig {
            vendureHost
            transitionToState
            exportableOrderStates
            openInNewTab
          }
        }
      `,
    })
  }

  async download(orderIds: string[], startsAt?: string, endsAt?: string): Promise<void> {
    try {
      const ids = orderIds?.join(',') || ''
      const urlParams = new URLSearchParams()
      urlParams.append('ids', ids)
      if (startsAt || endsAt) {
        urlParams.append(
          'from',
          startsAt ? dayjs(startsAt).toISOString() : dayjs().subtract(1, 'day').toISOString(),
        )
        urlParams.append('to', endsAt ? dayjs(endsAt).toISOString() : dayjs().toISOString())
      }
      const res = await fetch(`${this.serverPath}/export-orders/export?${urlParams.toString()}`, {
        headers: this.getHeaders(),
      })
      if (!res.ok) {
        const json = await res.json()
        throw Error(json?.message)
      }
      const header = res.headers.get('Content-Disposition')
      const parts = header!.split(';')
      const filename = parts[1].split('=')[1].replace(/"/g, '')
      const blob = await res.blob()
      await this.downloadBlob(blob, filename)
    } catch (err: any) {
      console.error(err)
      this.notificationService.error(err.message)
    }
  }

  async preview(templateString: string) {
    try {
      const template = templateString
      const res = await fetch(`${this.serverPath}/export-orders/export/test`, {
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ template }),
      })
      if (!res.ok) {
        const json = await res.json()
        throw Error(json?.message)
      }
      const blob = await res.blob()
      await this.downloadBlob(blob, 'test-invoice.pdf', true)
    } catch (err: any) {
      console.error(err)
      this.notificationService.error(err?.message)
    }
  }

  private getHeaders(): Record<string, string> {
    const headers: Record<string, string> = {}
    const channelToken = this.localStorageService.get('activeChannelToken')
    if (channelToken) {
      headers['vendure-token'] = channelToken
    }
    const authToken = this.localStorageService.get('authToken')
    if (authToken) {
      headers.authorization = `Bearer ${authToken}`
    }
    return headers
  }

  private async downloadBlob(blob: Blob, fileName: string, openInNewTab = true): Promise<void> {
    const blobUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.setAttribute('hidden', 'true')
    a.href = blobUrl

    if (!this.config.openInNewTab) {
      a.download = fileName
    }
    a.setAttribute('target', '_blank')
    a.click()
  }
}
