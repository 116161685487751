import { registerDashboardWidget } from '@vendure/admin-ui/core'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'

export default [
  registerDashboardWidget('delivery-widget', {
    title: _('delivery-widget.title'),
    supportedWidths: [4, 6, 8, 12],
    loadComponent: () =>
      import('./components/delivery-widget.component').then((m) => m.DeliveryWidgetComponent),
  }),
]
