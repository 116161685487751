import { registerDashboardWidget } from '@vendure/admin-ui/core'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'

export default [
  registerDashboardWidget('missed-deliveries-widget', {
    title: _('missed-deliveries-widget.title'),
    supportedWidths: [4, 6, 8, 12],
    loadComponent: () =>
      import('./components/missed-deliveries-widget.component').then(
        (m) => m.MissedDeliveriesWidgetComponent,
      ),
  }),
]
