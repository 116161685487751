import { NgModule } from '@angular/core'
import {
  registerDashboardWidget,
  registerDataTableComponent,
  registerHistoryEntryComponent,
  SharedModule,
  setDashboardWidgetLayout,
} from '@vendure/admin-ui/core'
import { OrderNotesWidgetComponent } from './components/order-notes-widget.component'
import { MarkNoteAsReadComponent } from './components/mark-note-as-read.component'
import { CustomOrderNoteEntryComponent } from './components/custom-order-note-entry.component'

@NgModule({
  imports: [SharedModule],
  providers: [
    registerDashboardWidget('order-notes-widget', {
      title: 'Unread Messages',
      supportedWidths: [4, 6, 8, 12],
      loadComponent: () =>
        import('./components/order-notes-widget.component').then(
          (m) => m.OrderNotesWidgetComponent,
        ),
    }),
    setDashboardWidgetLayout([
      { id: 'order-notes-widget', width: 12 },
      { id: 'delivery-widget', width: 6 },
      { id: 'missed-deliveries-widget', width: 6 },
    ]),
    registerDataTableComponent({
      component: MarkNoteAsReadComponent,
      tableId: 'latest-orders-widget-list',
      columnId: 'markNoteAsRead',
    }),
    registerHistoryEntryComponent({
      type: 'ORDER_NOTE',
      component: CustomOrderNoteEntryComponent,
    }),
  ],
  declarations: [OrderNotesWidgetComponent],
})
export class OrderNotesWidgetModule {}
