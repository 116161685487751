import { NgModule } from '@angular/core'
import { registerFormInputComponent, SharedModule } from '@vendure/admin-ui/core'
import { VoyadoService } from './voyado.service'
import { StopComponent } from './components/stop.component'

@NgModule({
  imports: [SharedModule],
  providers: [registerFormInputComponent('stop-component', StopComponent), VoyadoService],
})
export class StopOrderDetailModule {}
