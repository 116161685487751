export const extensionRoutes = [  {
    path: 'extensions/export-orders',
    loadChildren: () => import('./extensions/9eebd58641751056fb6144e25e84c3eb1664074717c3cf9a9c891039ad018ff6/order-export.module').then(m => m.OrderExportModule),
  },
  {
    path: 'extensions/badges',
    loadChildren: () => import('./extensions/1a38455509fe6efe36a2667ca74420222e53a80d401272638cf8d95f66bb5a21/badge.module').then(m => m.BadgeModule),
  },
  {
    path: 'extensions/delivery-widget',
    loadChildren: () => import('./extensions/delivery-widget-ui/routes'),
  },
  {
    path: 'extensions/missed-deliveries-widget',
    loadChildren: () => import('./extensions/missed-deliveries-widget-ui/routes'),
  }];
