import { Injectable, OnInit } from '@angular/core'

@Injectable()
export class VoyadoService implements OnInit {
  constructor() {}

  ngOnInit() {}

  async locationForHash(hashId: string) {
    return fetch(
      `https://iceman-prod.azurewebsites.net/api/integration/locationforhash?stopHashId=${hashId}`,
    ).then((res) => res.json().then(({ data }) => data))
  }

  async getNearestHashedStops({ latitude, longitude, limit }) {
    return fetch(
      `https://iceman-prod.azurewebsites.net/api/integration/getnearesthashedstops?latitude=${latitude}&longitude=${longitude}&limit=${limit}`,
    ).then((res) => res.json().then(({ data }) => data))
  }

  async getNextTimesForHashedStopId(stopHashId: string) {
    return fetch(
      `https://iceman-prod.azurewebsites.net/api/integration/getnexttimesforhashedstopid?stopHashId=${stopHashId}`,
    ).then((res) => res.json().then(({ data }) => data))
  }
}
